
import moment from 'moment';
import { getReservationReviewList } from '@/api/reservationReview';
import { getStoreInfo } from '@/api/storeInfo';
import {
  getLat,
  getLng,
  setLat,
  setLng,
  setReservation,
} from '@/utils/cookies';
import { Component, Vue, Watch } from 'vue-property-decorator';
import Calendar from '@/components/Calendar/index.vue';
import { getReservationCalendar, getReservationItem } from '@/api/reservationItem';
import { getReservationItemHour } from '@/api/reservationItemHour';
import { getReservationItemDayInfo } from '@/api/reservationItemDay';
import { addViewCount } from '@/api/hits';
import { addRecentList } from '@/utils/localStorage';
import Slick from 'vue-slick';
import { Leisure } from './leisure2';

@Component({
  components: {
    Calendar,
    Slick,
  },
})

export default class extends Vue {
  created() {
    this.initKakao();
    this.slickLoading();
  }

  mounted() {
    this.getStoreInfo();
    this.getReviewList();
    // this.getReservationCalendar();
    // this.getReservationItem();
  }

  private reservationDialogVisible =false;

  private selectedReservationItemUid = '';

  private async openReservationDialog(itemUid: string) {
    this.selectedDay = '';
    this.selectedHourUid = '';
    this.hourList = [];
    this.selectedReservationItemUid = itemUid;
    await this.getReservationItem();
    await this.getReservationCalendar();
    setTimeout(() => {
      const calendarRef: any = this.$refs.calendar;
      if (calendarRef) calendarRef.setDate(new Date(this.detailQuery.useDate), new Date(this.detailQuery.useDate));
      this.calendarData = [];
      this.handleChoiceDate(this.detailQuery.useDate, this.detailQuery.useDate);
    }, 100);
    this.reservationDialogVisible = !this.reservationDialogVisible;
  }

  private industryIdx = 0;

  private curTab = 1;

  private handleClickAccomodationSlide(str: string) {
    const slick = (this.$refs.accomodationBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private loading = true;

  private currentIndex = 0; // 슬라이드현재페이지

  private locationVisible = false;

  private map = null;

  private datePickerVisible = false;

  private showSection1 = true;

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private storeInfo = {
    name: '',
    introduce: '',
    tags: [],
    address: '',
    addressDetail: '',
    lat: '',
    lng: '',
    inquiryTel: '',
    basicInfo: '',
    operationTimeInfo: '',
    equipmentInfo: '',
    parkingInfo: '',
    locationInfo: '',
    reserveUrl: '',
    distance: '',
    images: [],
    companyName: '',
    bossName: '',
    companyAddress: '',
    companyAddressDetail: '',
    profileFileUid: '',
    greeting: '',
    minPrice: 0,
    reviewCount: 0,
    avgScore: '0.0',
  }

  private detailQuery: any = {
    lat: '37.566826',
    lng: '126.9786567',
    useDate: this.$route.query.startDate ? this.$route.query.startDate : moment().format('YYYY-MM-DD'),
  }

  // private listQuery = {
  //   useDate: moment().format('YYYY-MM-DD'),
  //   people: 1,
  //   round: 0,
  // }

  private reserveForm = {
    ticket1: 1,
    ticket2: 1,
    ticket3: 1,
  }

  private review = {
    propileName: '루피재욱',
    propileAmount: '55',
    propilePhoto: '15',
    rate: 4,
    likeCount: 0,
  }

  private reviewList = [];

  private reviewQuery = {
    page: 0,
    size: 10,
    businessIdx: this.$route.params.idx,
  }

  private reviewNoMore = false;

  private getStoreInfo() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.detailQuery.lat = lat;
      this.detailQuery.lng = lng;
      this.getStoreInfoDetail();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.detailQuery = {
            ...this.detailQuery,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          this.getStoreInfoDetail();
        },
        (error) => {
          this.getStoreInfoDetail();
        },
      );
    }
  }

  private getStoreInfoDetail() {
    this.loading = true;
    getStoreInfo(this.$route.params.idx, this.detailQuery).then((res) => {
      this.industryIdx = res.data.industryIdx;
      addRecentList(res.data, window.location.pathname);
      this.storeInfo = res.data;
      this.formattedLeisureInfo(this.storeInfo);
      this.loading = false;
    }).then(() => {
      addViewCount({ businessIdx: this.$route.params.idx });
    });
  }

  private formatTime(str: string) {
    const start = str.substring(0, 2);
    const end = str.substring(2, str.length);
    const result = `${start}:${end}`;

    return result;
  }

  private handleViewLocation() {
    if (this.storeInfo.lat && this.storeInfo.lng) {
      this.locationVisible = !this.locationVisible;
      this.$nextTick(() => {
        this.initMap(this.storeInfo.lat, this.storeInfo.lng);
      });
    } else {
      this.$message.error('지도를 불러오는데 실패했습니다.');
    }
  }

  private initKakao() {
    if (!(window as any).kakao || (window as any).kakao.maps) {
      const script = document.createElement('script');
      script.src = `${window.location.protocol}//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.VUE_APP_KAKAO_CLIENT_ID}&libraries=services`;
      document.head.appendChild(script);
    }
  }

  private initMap(lat: any, lng: any) {
    const container = document.getElementById('map');
    const option = {
      center: new (window as any).kakao.maps.LatLng(lat, lng),
      level: 3,
    };
    this.map = new (window as any).kakao.maps.Map(container, option);
    const markerPosition = new (window as any).kakao.maps.LatLng(lat, lng);
    const marker = new (window as any).kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(this.map);

    const content = `<div class="gis-item">${this.storeInfo.name}</div>`;
    const customOverlay = new (window as any).kakao.maps.CustomOverlay({
      map: null,
      position: markerPosition,
      content,
      yAnchor: 2.4,
      visible: false,
    });
    (window as any).kakao.maps.event.addListener(marker, 'mouseover', () => {
      customOverlay.setMap(this.map);
    });
    (window as any).kakao.maps.event.addListener(marker, 'mouseout', () => {
      customOverlay.setMap(null);
    });
    (window as any).kakao.maps.event.addListener(marker, 'click', () => {
      (window as any).open(`https://map.kakao.com/link/map/${this.storeInfo.name},${lat},${lng}`);
    });
  }

  private getReviewList() {
    getReservationReviewList(this.reviewQuery).then((res) => {
      this.reviewList = this.reviewList.concat(res.data.content);
      this.reviewNoMore = res.data.last;
    });
  }

  private handleMoreReviewList() {
    this.reviewQuery.page += 1;
    this.getReviewList();
  }

  private handleReservebtn() {
    this.showSection1 = !this.showSection1;
  }

  private informationStatus = '';

  private handleInformationMore() {
    this.informationStatus = '--open';
    this.informationStatus = 'expanded';
  }

  private people = 1;

  private active = '';

  private selectedHourUid = '';

  private reservationItem = {
    address: '',
    addressDetail: null,
    approvalStatus: false,
    callStatus: true,
    category: { uid: '', name: '' },
    continueStatus: true,
    detailDescription: '',
    directStatus: true,
    facilityCategoryName: null,
    formFileUid: null,
    freeStatus: false,
    hourUnitStatus: false,
    images: [],
    inquiryNumber: '',
    managerName: '',
    maxPeople: '',
    name: '',
    onlineStatus: true,
    postCode: '',
    price: 0,
    rangeInfiniteStatus: true,
    reserveEnable: false,
    restriction: '',
    settingUnitStatus: null,
    type: { uid: '', name: '' },
    uid: '',
  }

  private handleClickSlick(refName: string, type: string) {
    if (this.$refs[refName]) {
      const reference: any = this.$refs[refName];
      if (type === 'prev') reference.prev();
      if (type === 'next') reference.next();
    }
  }

  private mainslickOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 400,
    // prevArrow: $('.iconslick-prev'),
    // nextArrow: $('.iconslick-next'),
    // draggable: true,
    pauseOnHover: true,
    arrows: false,
  };

  private slickLoad = false;

  private isLoading = true;

  private slickLoading() {
    // console.log('시작');
    this.isLoading = true;
    window.setTimeout(() => {
      this.isLoading = false;
      this.slickLoad = true;
      // console.log('종료');
    }, 3000);
  }

  private hourList: any = []; // 회차 선택 목록

  private hourLoading = false;

  private calendarLoading = false;

  // private selectedMonth = moment().format('YYYY-MM');

  private dayInfo = {
    useDate: '',
    reserveCount: 0,
    maxPeople: 0,
    price: 0,
  };

  private dayInfoLoading = false;

  private async getReservationItem() {
    await getReservationItem(this.selectedReservationItemUid).then((res) => {
      this.reservationItem = res.data;
    });
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedHourUid = '';
    this.selectedDay = startDate;
    if (this.reservationItem.settingUnitStatus === 0) {
      this.getReservationItemDay();
    } else if (this.reservationItem.settingUnitStatus === 1) {
      this.getReservationItemHour();
    }
  }

  private async getReservationCalendar() {
    this.calendarLoading = true;
    await getReservationCalendar(this.selectedReservationItemUid, { selectedMonth: `${this.selectedMonth}` }).then((res) => {
      this.calendarData = res.data;
      this.calendarLoading = false;
    });
  }

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.getReservationCalendar();
  }

  private getReservationItemHour() {
    this.hourLoading = true;
    getReservationItemHour(this.reservationItem.uid, { searchDate: this.selectedDay }).then((res) => {
      this.hourList = res.data;
      this.hourLoading = false;
    });
  }

  private getReservationItemDay() {
    this.dayInfoLoading = true;
    getReservationItemDayInfo(this.reservationItem.uid, { useDate: this.selectedDay }).then((res) => {
      this.dayInfoLoading = false;
      this.dayInfo = res.data;
    });
  }

  private handleReserve() {
    if (this.getMaxPeople() === 0) {
      alert('선택할 수 없는 날짜입니다.');
      return;
    }
    this.scr();
    setReservation({
      hourUid: this.selectedHourUid,
      useDate: this.selectedDay,
      people: this.people,
    });
    this.$router.push({
      name: 'LeisureReserve',
      params: {
        idx: this.$route.params.idx,
        itemUid: this.selectedReservationItemUid,
      },
      query: {
        industryIdx: this.industryIdx.toString(),
      },
    });
  }

  private noScr() {
    document.documentElement.classList.add('noscr');
  }

  private scr() {
    document.documentElement.classList.remove('noscr');
  }

  private leisureSplit = {
    basicInfo: [] as Object[],
    parkingInfo: [] as Object[],
    surroundInfo: [] as Object[],
    locationInfo: [] as Object[],
    operationList: [] as Object[],
    operationStartDate: '',
    operationEndDate: '',
    etcInfo: [] as Object[],
  }

  private formattedLeisureInfo(leisure: any) {
    if (leisure) {
      this.leisureSplit.basicInfo = leisure.basicInfo ? leisure.basicInfo.split('\n').filter(Boolean) : [''];
      this.leisureSplit.parkingInfo = leisure.parkingInfo ? leisure.parkingInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.leisureSplit.surroundInfo = leisure.surroundInfo ? leisure.surroundInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.leisureSplit.locationInfo = leisure.locationInfo ? leisure.locationInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.leisureSplit.operationList = leisure.operationList ? leisure.operationList : ['입력된 정보가 없습니다'];
      this.leisureSplit.operationStartDate = leisure.operationList[0].startDate ? leisure.operationList[0].startDate : ['입력된 정보가 없습니다'];
      this.leisureSplit.operationEndDate = leisure.operationList[0].endDate ? leisure.operationList[0].endDate : ['입력된 정보가 없습니다'];
      this.leisureSplit.etcInfo = leisure.etcInfo ? leisure.etcInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
    }
  }

  private handleReset() {
    this.people = 1;
    this.selectedHourUid = '';
    this.selectedDay = '';
    this.hourList = [];
    this.dayInfo = {
      useDate: '',
      reserveCount: 0,
      maxPeople: 0,
      price: 0,
    };
    const calendarRef: any = this.$refs.calendar;
    if (calendarRef) calendarRef.clearClickClass();
  }

  private getMaxPeople() {
    let maxPeople = 1;
    if (this.selectedHourUid) {
      const hourIndex = this.hourList.findIndex((hour: any) => hour.uid === this.selectedHourUid);
      if (hourIndex > -1) {
        maxPeople = this.hourList[hourIndex].maxPeople - this.hourList[hourIndex].reserveCount;
      }
    } else if (this.dayInfo) {
      maxPeople = this.dayInfo.maxPeople - this.dayInfo.reserveCount;
    }
    return maxPeople;
  }

  private isExpanded = false;

  private shortenedIntroduction() {
    const lines = this.storeInfo.introduce.split('\n');
    return `${lines.slice(0, 4).join('\n')}...`;
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  private likeStatus = false;

  private handleClickLike() {
    this.likeStatus = !this.likeStatus;
    if (this.likeStatus) {
      this.review.likeCount += 1;
      this.active = 'active';
      return true;
    }
    this.review.likeCount -= 1;
    this.active = '';
    return false;
  }

  private getPrivateData() {
    const privateData = this.review.likeCount;
    return privateData;
  }

  private ShowButton() {
    let str = this.$data.storeInfo.introduce;
    if (str === null) str = '';
    const introduction = str;
    const introductionLength = introduction.length;
    const windowWidth = this.getWindowWidth();

    if (windowWidth >= 1024) {
      return this.$data.informationStatus !== 'expanded' && introductionLength >= 450;
    }
    return this.$data.informationStatus !== 'expanded' && introductionLength >= 150;
  }

  private getWindowWidth(): number {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  // 슬라이드 현재페이지 숫자
  private carouselChange(index: number): void {
    if (this.storeInfo.images.length <= index) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex = index;
    }
  }

  private handleKakaoShare() {
    const baseUrl = process.env.VUE_APP_TARGET;
    const shareUrl = window.location.href;
    (window as any).Kakao.cleanup();
    (window as any).Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY);
    (window as any).Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: this.storeInfo.name,
        description: this.storeInfo.introduce,
        imageUrl: this.storeInfo.images.length > 0 ? `${baseUrl}/api/attached-file/${this.storeInfo.images[0]}` : `${baseUrl}/favicon.ico`,
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      social: {
        likeCount: 0,
        commentCount: 0,
        sharedCount: 0,
      },
      buttons: [
        {
          title: '보러가기',
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    });
  }

  private curTabs: number[] = [1, 2, 3, 4];

  private toggleSection(section: number): void {
    const index = this.curTabs.indexOf(section);
    if (index !== -1) {
      // 섹션이 이미 열려있는 경우 닫기
      this.curTabs.splice(index, 1);
    } else {
      // 새로운 섹션 열기
      this.curTabs.push(section);
    }

    // 아이콘 클래스 업데이트
    for (let i = 1; i <= 4; i += 1) {
      const icon = this.$refs[`icon${i}`] as HTMLElement;
      const isOpen = this.curTabs.includes(i);
      if (isOpen) {
        icon.classList.remove('el-icon-arrow-down');
        icon.classList.add('el-icon-arrow-up');
      } else {
        icon.classList.remove('el-icon-arrow-up');
        icon.classList.add('el-icon-arrow-down');
      }
    }
  }

  private isSectionVisible(section: number): boolean {
    return this.curTabs.includes(section); // 현재 섹션의 가시성 여부 반환
  }
}
